@import url('https://fonts.googleapis.com/css2?family=Source+Sans+3:ital,wght@0,200..900;1,200..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Rubik', serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

textarea::placeholder {
  opacity: 0.6;
  font-style: italic;
  font-size: 14px;
}

.transition-width {
  transition-property: width;
}
.source-sans-3  {
  font-family: "Source Sans 3", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}
// .swiper-wrapper {
//   background-color: brown !important;
//   display: flex !important;
//   width: 100% !important;
//   flex-wrap: nowrap !important;
//   align-items: center !important;
//   justify-content: center !important;
// }

.dark-shadow {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.3), 0 4px 6px -4px rgba(0, 0, 0, 0.4);
}

// slide up animation
@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.slide-up {
  animation: slideUp 0.5s ease-out forwards;
}

// slide down animation
@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-100%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.slide-down {
  animation: slideDown 0.5s ease-out forwards;
}

// slide down animation for modal
@keyframes slideDownModal {
  from {
    opacity: 0;
    transform: translateY(-100%);
  }
  to {
    opacity: 1;
    transform: translateY(20%);
    padding: 4% 0;
  }
}

.slide-down-modal {
  animation: slideDownModal 0.5s ease-out forwards;
}

// for dropdown modal post title
.two-line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;  
  overflow: hidden;
  text-overflow: ellipsis;
}

// for ipad pro screen
@media (min-width: 1024px){
  .padding-adjust {
    padding: 0 10%;
  }

  .tagline {
    width: 100%;
    overflow-y: scroll;
  }
}


@layer utilities {
  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }
}